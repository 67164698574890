export default {
  "sign_in": "Sign In",
  "sign_up": "Sign Up",
  "sign_out": "Sign Out",
  "my_experiment": "📐 My experiment",
  "lookup": "lookup",
  "notice_board": "Notice board",
  "upload": "Upload",
  "analysis": "Analysis",
  "report": "Report",
  "write_report_button": "Write a report",
  "profile": "Profile",
  "login": "Login",
  "user_name" : "Name",
  "input_name" : "Write your name here.",
  "input_email" : "write your E-mail here",
  "input_pw": "write your PW here",
  "confirm_pw": "rewrite your PW here",
  "check_pw": "check PW",
  "pw_diff" : "You have entered different passwords. check and try again",
  "title": "Arduino Science Experiments",
  "arduino_equipment_button": "Arduino Experiment Equipment",
  "language": "Language",
  "contents": "Contents",
  "download_button": "Download",
  "find_password_content": "We will send you a password reset link to the email you entered.",
  "find_button": "Find",

  // main.page.js 
  "science_content": "Your science exploration ability can be recognized as a universal one. By having the experience of writing a science report, your abilities will be elevated. With Flow Studio, anyone can easily complete a science report.",
  "arduino_content": "How can one get equipment for science experiments? The equipment that professionals use is very expensive and humongous at times, but there is no need for you to use that kind of equipment. With Arduino, you can make your own little equipment for experiments. Flow Studio provides moduled service so that anyone can easily use Arduino for experiments.",
  "data_gathering_content": "Have you completed your equipment for your science experiment? That device will provide you with data. The data yielded as quantitative values will disappear if it is not recorded. Flow Studio provides non-volatile Data Gathering service. In quality data, we will find scientific meaning.",
  "graph_content": "If you have acquired data, you have to process it in a form that can be easily understood by anyone. Not used to turning data into a graph? No worries. In Flow Studio, simply uploading data can turn it into a graph.",
  "fitting_content": "Beyond showing data as a graph, yielding meaning from it is very important. Your science exploration ability can be exhibited from this process. But for a long time, doing this kind of work amidst complicated formulae and abundant data has been way too professional. With Flow Studio, you can use our Fitting service developed for beginners and students.",
  "analysis_content_main": "Now your scientific exploration is complete. How do your hypothesis and your experiment data align and how are they a bit different? More importantly, what meaning do you take from the result? And what is the effect that it will have on our society?",
  
  "institution_title": "Institution",
  "institution_content": 'The value of Flow Studio is established by building data about the field of interest and taste clients have regarding experimentation based on their accumulated experiment information. It is also built up by suggesting equipment and experiment themes that clients would want. Flow Studio will enable teens and the public to experiment effortlessly and will start to inform what experiment topics are popular nowadays in this society.\n' +
  'The value of Flow Studio is focused more on the relationships it has than the price of things that it owns. Flow studio has a tight relationship with students all around the world in R&E activity. Students write reports on Flow Studio’s contactless experiment report writing platform ‘Grafting’. Flow Studio is obtaining a lot of information on what kind of research students are executing.\n' +
  'The experiment equipment of Flow Studio is the result of a concept called ‘Maker Culture’. Using Digital Fabrication technology, it is a company that shares and communicates with the world. All equipment is made from eco-friendly material so there is no problem in students using it and supports students in developing countries so that all students around the world can dream of becoming a scientist and grow.\n',

  "inclined_plane_title": "Inclined plane",
  "inclined_plane_content": 'How did Galileo Galilei discover the Law of Falling Motion? He established it through experiments. Galileo Galilei rolled a bronze margle on a slope made of wood. On the wooden slope, there were little cuts of regular intervals so that the bronze marble made noise when it rolled down. At that time, there wasn’t such a thing as a clock so he had to measure time by singing folk songs. A bronze marble, a block of wood with cuts and a folk song together was the most advanced experiment technology at that time to discover the Law of Falling Motion.\n' + 
  'Flow Studio revived his great quest for knowledge. The reinterpreted experiment equipment made with Arduino and 3D printing technology can easily be used by anyone and can very precisely measure the free fall time of an object. Flow Studio will become a bridge that overcomes 400 years so that students all over the world can feel the euphoria of scientific discovery that Galileo Galilei would have felt.',

  "flow_studio_title": "Flow Studio",
  "flow_studio_content": 'Flow Studio does research on teen science experiment education. Regarding a diverse range of topics, we are planning experiment activity from the bottom, the level of experiment equipment planning. Currently as an activity of technology business, we are establishing the business model of a teen experiment equipment and contactless experiment report writing platform \‘Grafting\’.\n' +
  'Unfortunately in Korea, there are no places for a student to get help doing science experiments and experiment equipment is either for professionals which is too expensive or a toy made for children.\n' +
  'Francis Bacon who said \‘Knowledge is Power\’ wrote about the scientific exploration method in his book \‘Novum Organum\’ and stated that many people should work together and execute scientific exploration. 400 years later, countries advocate scientific exploration ability as their power and individuals also get noticed when their science exploration abilities are exceptional.\n' +
  'Science is not just for scientists. All the people around us can carry out scientific activity in their daily lives and as this ability grows individuals and the society as a whole grows as well. It should be noted that Bacon, who presented a scientific exploration methodology where new quality knowledge can be created, didn not want it to be exclusive to nobility or the rich.\n' +
  'Well designed experiment equipment is an important factor in determining the quality of the result of an experiment. But it is impossible for a student to make equipment for experimenting easily at a low price. Flow Studio solves this problem through Arduino and 3D printing. Students can design experiment devices that they need for their experiment easily and get it in person.\n' +
  'There are a lot of companies that sell science experiment equipment. But there are no companies that do research on teen science experiment activity. With that being said, there exists no technology for improving the essence of student science experiment activity. Flow Studio’s contactless experiment report writing platform \‘Grafting\’ works with science experiment devices so that students can complete more quantitative and scientific reports.\n' +
  'Similar to POSTECH, a university that in the 1980’s was in the middle of nowhere regarding science technology made a 4th generation light source accelerator,  Edutech company Flow Studio will create the opportunity of guiding student experiment activity all around the world and exporting experiment equipment.',

  "inquery_title": "Equipment Inqueries",
  "inquery_content": "Flow Studio connects with teachers all over the world. Are you hesitant while that is an experiment that your students want to try out? Are you putting too much time and money into experiment equipment? The moduled experiment equipment system of Flow Studio can be widely used in any experiment. Through consultation specifize the spec of your experiment equipment and get customized experiment equipment ASAP!",

  "grafting_title": "What is Grafting?",
  "grafting_content": "Grafting is a compound word of graph + fitting. It is a word that implies comparing and contrasting hypotheses and experiments during the scientific exploration process of graphing the results of scientific experiments and fitting them into mathematical models. Also, Grafting holds the meaning of incorporating two different plants together. This comes from the fact that Arduino and 3D printing technology are fused to create a new result which is science experimenting.",

  "confirm_button": "Confirm",
  "store_button": "Store",

  // footer.js
  "flow_studio": "Flow Studio",
  "address": "ChangeUp Ground Room 526, Cheongam-ro, Pohang-si, Republic of Korea",
  "ceo": "CEO Aayon JEONG",
  "business_number": "Business registration No. 174-81-02953",
  "mail_order_sales_registration_number": "Mail-order-sales registration No. 2023-경북포항-0026",
  "call_number": "Contact 054-221-7989",

  // main.js
  "notice_board_content": "You can write announcements for your teammates here!",
  "participating_teammates_title": "Participating teammates",
  "total_experiment_completion_rate": "Total report completion rate",
  "notice_title_placeholder": "Enter the title of the announcement",
  "notice_content_placeholder": "Enter the content of announcement",

  // add-experiment.js, add-experiment-id.js
  "enter_info_title": "Enter Information",
  "enter_info_content": "Please enter experiment information.",
  "experiment_template": "Experiment Template",
  "experiment_title": "Experiment Title",
  "experiment_explanation": "Experiment Explanation",
  "experiment_code": "Experiment Code",
  "experiment_search": "Find experiment",
  "join_experiment_content": "You can join the experiment via the experiment code.",
  "create_experiment_button": "Make New Experiment",
  "join_experiment_button": "Join Experiment",
  "join_experiment_title": "Join Experiment",
  "create_experiment_title": "Add Experiment",
  "cancel_button": "Cancel",
  "add_button": "Add",

   // analysis.js
   "analysis_content": "Draw a graph with a fitting function by choosing the uploaded experiment data!",
   "choose_data_title": "Choose experiment data",
   "select_x_axis": "Select x-axis data",
   "select_y_axis": "Select y-axis data",
   "basic_info_title": "Basic Information",
   "basic_info_subtitle": "Title",
   "basic_info_title_font_size": "Title font size",
   "basic_info_axis_font_size": "Axis font size",
   "basic_info_x_axis_name": "X-axis name",
   "basic_info_x_axis_min_value": "X-axis min value",
   "basic_info_x_axis_max_value": "X-axis max value",
   "basic_info_x_axis_unit": "X-axis unit",
   "basic_info_x_axis_subunit": "X-axis subunit",
   "basic_info_y_axis_name": "Y-axis name",
   "basic_info_y_axis_min_value": "Y-axis min value",
   "basic_info_y_axis_max_value": "Y-axis max value",
   "basic_info_y_axis_unit": "Y-axis unit",
   "basic_info_y_axis_subunit": "Y-axis subunit",
   "basic_info_color": "Data color",
   "basic_info_point_size": "Point size",
   "subunit_content": "The number of graph axes is displayed as unit multiples. If it is too dense, it may be omitted.",
   "add_data": "Add Data",
   "remove_data": "Remove Data",

   "fitting_function_title": "Fitting Function",
   "fitting_function_content": "Choose a function.",

   "graph_title": "Graph",
   "graph_download_button": "Download graph image",

  "polynomial_title": "Polynomial Function",
  "polynomial_content": "It is used when the control variable and the dependent variable are in a polynomial relationship. For example, the correlation between the free fall height and the free fall time of motion of a falling body is Height = 0.5 x 9.8 x Time². It is in the relation of a polynomial function.",

  "transcendental_title": "Transcendental Function",
  "transcendental_content": "However, one cannot explain all scientific phenomena with polynomial functions. Transcendental functions include trigonometric functions such as a sine function, e​​xponential functions and log functions. For instance, chain reactions use exponential functions. In chemical reaction kinetics, the reaction rate according to the concentration of the reaction substance can be induced as a log function.",

  "newton_title": "Newton’s Law of Cooling",
  "newton_content": "Do you explore the phenomena of samples getting cold or hot? Newton’s Law of Cooling is used to analyze this kind of phenomena. A factor called heat transfer coefficient is computed through this model. Analyze how fast an item can be frozen and heated up!",

  "logistic_title": "Logistic function",
  "logistic_content": "In biology, the growth of a population follows a logistic function. The tendency of the growth of yeast and people contaminated with a virus can both be analysed with a logistic function. A logistic function is determined by two factors, growth rate and environment receptive capacity.",

  // upload.js
  "upload_explanation": "You can upload experiment data! .txt, .jpg, .png formats are supported.",
  "select_file": "Select File",

  // report.js
  "report_content": "If you answer every question, you can download a complete experiment report as a Word(.docx) file!",
  "experiment_completion_rate": "Experiment Completion Rate",
  "select_teammate_subtitle": "You can click on a teammate to check out the content of their report.",

  // gpt.js
  "gpt_content": "Make report using ChatGPT!",
  "age": "age",
  "content": "content",
  "x_axis": "x axis",
  "y_axis": "y axis",
  "method": "method",
  "condition": "condition",
  "result": "result",
  "difference": "difference",
  "disappoint": "disappoint",
  "plan": "plan",
  "create_report_button": "create report",

  // answer-card.js
  "edit_button": "Edit",
  "edit_title": "Edit report",

  // lookup.js
  "login_alert": "Please login first!",
  "experiment_list_title": "My experiments",
  "experiment_code_title": "Experiment code",

  //profile.page.js
  'profile_pic': "Profile picture",
  "choose_Image": "Choose Image",
  "email": "Email",
  "change_info": "Change Information",
  "consulting_service": "Consulting Service",
  "not_in_use": "Not in Use",
  "use_service": "Use Service",
  "refund_service": "Refund Service",
  "price": "Price : 400,000₩ monthly",
  "duration": "Services are provided 30 Days from purchase.",
  "refund_policy": "Refund Policy",
  "try_later": "Try Later",
  "apply_monthly_pay": "Apply Monthly Pay",
  "in_use": "In Use",
  "more": "More",
}