import {useEffect, useState} from "react";
import axios from "axios";
import {Table} from "semantic-ui-react";
import moment from "moment";
import Profile from "../../utils/profile";

function ManageUser(){
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true }).then((res) => {
      setUsers(res.data);
    });
  }, [])

  return <div>
    <h2>User Management</h2>

    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Date Joined</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          users.map((e) => {
            return <Table.Row>
              <Table.Cell className={'has-item-vcentered'}>
                <Profile size={36} src={e.profile_url} />
                <p className='ml-5'>{e.name}</p>
              </Table.Cell>
              <Table.Cell>{e.email}</Table.Cell>
              <Table.Cell>{e.type}</Table.Cell>
              <Table.Cell>{moment(e.created_at).format('YYYY. MM. DD. HH:mm')}</Table.Cell>
              {/* <Table.Cell><button>비밀번호변경</button></Table.Cell> */}
            </Table.Row>
          })
        }
      </Table.Body>
    </Table>
  </div>
}

export default ManageUser