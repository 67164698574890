export default {
  "sign_in": "로그인",
  "sign_up": "회원가입",
  "sign_out": "로그아웃",
  "my_experiment": "📐 내 실험",
  "lookup": "조회",
  "notice_board": "공지 게시판",
  "upload": "등록",
  "analysis": "분석",
  "report": "보고서",
  "gpt": "ChatGPT",
  "write_report_button": "보고서 작성하러가기",
  "profile": "프로필",
  "login": "로그인",
  "user_name": "이름",
  "input_name": "이름을 적어주세요.",
  "input_email" : "사용할 이메일을 적어주세요",
  "input_pw": "비밀번호를 입력하세요",
  "confirm_pw": "비밀번호를 다시 한번 입력해주세요",
  "check_pw": "비밀번호 확인",
  "pw_diff" : "서로 다른 비밀번호를 입력하셨습니다. 확인후 다시 시도하세요.",
  "title": "아두이노 과학실험",
  "arduino_equipment_button": "아두이노 과학실험 장치",
  "language": "언어 설정",
  "contents": "콘텐츠",
  "download_button": "다운로드",
  "find_password_content": "입력하신 이메일로 비밀번호 재설정 링크를 보내드립니다.",
  "find_button": "찾기",

  // main.page.js
  "science_content": "당신의 과학적 탐구능력은 보편적 능력으로 인정받을 수 있습니다. 과학보고서 작성 경험을 함으로써 그 능력은 한 차원 더 높아집니다. 플로우 스튜디오와 함께라면 누구나 쉽게 과학보고서를 완성할 수 있습니다.",
  
  "arduino_content": '과학실험을 위해 필요한 과학실험장치는 어떻게 구해야 할까요? ' +
  '전문가들이 사용하는 장치들은 매우 비싸고 거대할 때가 많지만, ' +
  '여러분들도 그런 장치를 사용해야만 하는 것은 아닙니다. ' +
  '아두이노를 이용하면 여러분들만의 작은 실험장치를 스스로 만들 수 있습니다.' +
  '플로우 스튜디오에서는 누구나 쉽게 아두이노를 이용할 수 있도록 모듈화된 서비스를 제공합니다.',
  
  "data_gathering_content": '과학실험장치를 완성하셨나요? 그 장치는 여러분에게 데이터를 제공할 것입니다. ' +
  '정량화된 수치로서 제공되는 데이터는 기록하지 않으면 휘발되어 사라져버립니다. ' +
  '플로우 스튜디오는 휘발되지 않는 데이터 게더링 서비스를 제공합니다. ' +
  '양질의 데이터속에서 우리는 과학적 의미를 찾아갈 것입니다.',
  
  "graph_content": '데이터를 얻으셨다면 누구나 쉽게 이해할 수 있는 형태로 가공해야 합니다. ' +
  '데이터를 그래프로 나타내는 일이 익숙하지 않다고요? ' +
  '걱정하지 마세요. 플로우 스튜디오에서는 간단히 데이터를 업로드하기만 하면 그래프로 나타낼 수 있습니다.',

  "fitting_content": '데이터를 그래프로만 나타내는 것을 넘어서 그 의미를 산출하는 일은 매우 중요합니다. ' +
  '이 과정을 통해 여러분의 과학적 탐구 능력은 발휘될 것입니다. ' +
  '하지만 복잡한 수식과 수많은 데이터 속에서 이러한 작업을 하는 일은 그동안 너무 전문적이었습니다. ' +
  '플로우 스튜디오와 함께 초보자와 학생들을 위해 개발된 피팅 기능을 이용해보세요.',

  "analysis_content_main": '이제 당신의 과학적 탐구는 완성되었습니다. ' +
  '당신이 생각했던 가설과 당신의 실험데이터는 어떤 부분에서 서로 잘 맞고 어떤 부분에서 조금 다른가요? ' +
  '더 중요한 것은 그 결과에 대해 당신이 생각하는 의미는 무엇인가요? ' +
  '그리고 우리 사회에 미칠 영향은 어떤 것인가요?',

  "institution_title": "기관소개",
  "institution_content": '플로우 스튜디오의 가치는 고객의 실험 정보를 통해 고객의 관심 실험 분야와 취향에 대한 데이터를 구축하고, 고객이 원할 만한 실험장치와 주제를 제안하는 방식을 통해 구축됩니다. 플로우 스튜디오는 청소년과 대중이 수월하게 과학실험을 수행할 수 있게 할 것이며 오늘날의 사회에서 인기 있는 과학실험 주제가 무엇인지 알려주기 시작할 것입니다.\n' +
  '플로우 스튜디오의 가치는 회사가 보유한 것들의 가격보다 플로우 스튜디오가 맺고 있는 관계에 더 비중이 있습니다. 플로우 스튜디오는 전 세계의 R&E 활동을 수행하는 청소년들과 긴밀한 관계를 맺고 있습니다. 청소년들은 플로우 스튜디오의 비대면 실험보고서 작성 플랫폼 ‘그래프팅’에서 보고서를 작성합니다. 회사는 청소년들이 어떤 연구를 수행하고 있는지에 대해 아주 많은 정보를 획득하고 있습니다.\n' +
  '플로우 스튜디오의 실험장치들은 메이커 문화라는 토양에서 뿌리내린 결실입니다. 디지털 패브리케이션 기술을 적극적으로 활용하여 전 세계와 공유하고 소통하는 기업입니다. 학생이 사용하는 데 문제가 없도록 모든 장치는 친환경적인 소재로 제작되며 전 세계 청소년들이 과학자의 꿈을 꾸고 성장할 수 있도록 개발도상국의 청소년을 지원합니다.',

  "inclined_plane_title": "빗면 낙하장치 소개",
  "inclined_plane_content": '갈릴레이 갈릴레오는 어떻게 낙하운동법칙을 발견했을까요? 그는 실험을 통해 낙하운동법칙을 정립했습니다. 그는 청동으로 만든 구슬을 나무로 만든 경사면에 굴렸습니다. 나무 경사면에는 일정한 간격으로 작은 홈이 패여서 청동구슬이 굴러감에 따라 소리가 났습니다. 당시에는 오늘날과 같은 시계가 없었기에 그는 민요를 불러서 시간을 측정했습니다. 청동구슬과 홈이 패인 나무, 그리고 민요가 그 시절 물체의 낙하운동법칙을 밝혀내기 위한 최첨단의 실험장치였던 셈입니다.\n' +
  '플로우 스튜디오는 그의 위대한 탐구정신을 되살려내었습니다. 아두이노와 3D 프린팅 기술을 접목하여 재해석한 그의 실험장치는 누구나 쉽게 사용할 수 있으며 매우 정확하게 물체의 낙하시간을 측정합니다. 전 세계의 학생들이 갈릴레이 갈릴레오가 느꼈을 과학적 발견의 쾌감을 함께 느낄 수 있도록, 플로우 스튜디오는 400년을 뛰어넘는 가교가 되었습니다.',

  "flow_studio_title": "플로우 스튜디오란?",
  "flow_studio_content": '플로우 스튜디오에서는 청소년 과학실험 교육에 대해 연구합니다. 다양한 주제들에 대해 실험장치 설계수준에서부터 실험 활동을 계획해 나가고 있습니다. 현재는 기술 사업화 활동의 하나로 청소년 실험장치와 비대면 실험보고서 작성 플랫폼 ‘그래프팅’에 대한 사업화 모델을 구축하고 있습니다.\n' +
  '우리나라는 안타깝게도 여건상 학생이 과학실험 활동을 수행하는 데 도움을 받을 곳이 없으며 실험장치는 너무 고가인 전문가용이거나 놀이 수준인 어린이용만 판매되는 것으로 보입니다. \n' +
  '‘아는 것이 힘’이라고 말했던 프랜시스 베이컨은 과학적 탐구방법에 대해 그의 저서 ‘신기관’에서 밝히고, 많은 사람이 협력하여 과학적 탐구를 수행하자는 선언을 하였습니다. 400년이 지난 오늘날, 국가는 과학적 탐구능력을 국력으로 내세우게 되었고 개인도 과학적 탐구능력이 높을수록 인정받게 되었습니다.\n' +
  '과학은, 직업과학자들만 하는 것이 아닙니다. 우리 주변의 사람들이 모두 생활과학 활동을 수행할 수 있고, 그런 활동을 수행하는 능력이 뛰어날수록 개인과 사회가 더 발전합니다. 새로운 양질의 지식을 생산해낼 수 있는 과학적 탐구방법론을 제시한 베이컨은 그 방법을 귀족이나 부자들만 독점하기를 바라지 않았다는 점에 주목할 필요가 있습니다.\n' +
  '잘 고안된 실험장치는 실험결과의 품질을 결정하는 중요한 요소입니다. 하지만 학생이 저렴한 가격으로 쉽게 실험장치를 만드는 일은 불가능합니다. 아두이노와 3D 프린팅을 활용해 플로우 스튜디오는 이러한 문제를 해결합니다. 학생들은 적은 노력으로도 자신이 구상한 실험에 필요한 실험장치를 쉽게 고안하고 실물로 받아볼 수 있습니다.\n' +
  '과학교구를 판매하는 회사들은 많습니다. 하지만 청소년 과학실험 활동에 대해 연구하는 기업은 존재하지 않습니다. 그러다 보니 청소년 과학실험 활동을 본질에서 향상할 기술은 없는 실정입니다. 플로우 스튜디오의 비대면 실험보고서 작성 플랫폼 ‘그래프팅’은 과학실험 장치와 맞물려 청소년이 스스로 더 정량적이고 과학적인 과학실험 보고서를 완성할 수 있도록 합니다.\n' +
  '1980년대, 제대로 된 과학실험장치를 만들 수 없었던 과학 불모지에서 4세대 방사광 가속기 등 세계적 수준의 거대 실험장치를 통해 최고의 연구성과를 발표하는 현재의 포스텍이 성장한 것처럼, 과학실험 교육 기술혁신으로 포항에서 전 세계 청소년 실험 활동을 지도하고 실험장치를 수출하는 기회를 에듀테크 기업 플로우 스튜디오가 만들어나갈 것입니다.',

  "inquery_title": "실험장치 의뢰문의",
  "inquery_content": '플로우 스튜디오는 전 세계의 교사들과 소통합니다. 학생들이 수행하고자 하는 실험이 있는데 망설이고 계시는가요? 너무 많은 시간과 비용을 실험장치에 쏟고 계시는가요? 플로우 스튜디오에서 모듈화한 실험장치 구성 시스템은 어떠한 실험주제에도 범용적으로 활용될 수 있습니다. 상담을 통해 실험장치의 사양을 구체화하고 가장 빠르게 전용 실험장치를 받아보세요!',
  
  "grafting_title": "그래프팅이란?",
  "grafting_content": "그래프팅은 graph+fitting의 합성어로 과학실험 결과를 그래프로 나타내고 수학적 모델로 피팅하는 과학적 탐구과정 중 가설과 실험의 비교 대조과정을 함축한 단어입니다. 또한, Grafting은 두 개의 서로 다른 식물을 접붙이는 과정이라는 의미로서, 아두이노와 3D 프린팅 기술을 융합하여 과학실험이라는 새로운 성과물이 창조되는 과정을 의미하기도 합니다.",

  "confirm_button": "확인",
  "store_button": "스토어 가기",

  // footer.js
  "flow_studio": "플로우 스튜디오",
  "address": "포항시 남구 청암로 87, 체인지업 그라운드 526호",
  "ceo": "대표 정아연",
  "business_number": "사업자 번호 174-81-02953",
  "mail_order_sales_registration_number": "통신판매업신고 제 2023-경북포항-0026",
  "call_number": "연락처 054-221-7989",

  // main.js
  "notice_board_content": "팀원들에게 전달할 공지 내용을 작성할 수 있습니다!",
  "participating_teammates_title": "참여 팀원",
  "total_experiment_completion_rate": "전체 실험 보고서 완성률",
  "notice_title_placeholder": "공지 제목을 입력하세요.",
  "notice_content_placeholder": "팀원에게 공지할 내용을 입력하세요.",

  // add-experiment.js, add-experiment-id.js
  "enter_info_title": "정보 입력",
  "enter_info_content": "실험 정보를 입력하세요.",
  "experiment_template": "실험 템플릿",
  "experiment_title": "실험 제목",
  "experiment_explanation": "실험 설명",
  "experiment_code": "실험 코드",
  "experiment_search": "실험 조회",
  "join_experiment_content": "실험 코드를 입력해서 실험에 참여할 수 있습니다.",
  "create_experiment_button": "새 실험 생성하기",
  "join_experiment_button": "실험 참가하기",
  "join_experiment_title": "실험 참가하기",
  "create_experiment_title": "실험 추가하기",
  "cancel_button": "취소",
  "add_button": "추가하기",
  

  // analysis.js
  "analysis_content": "업로드한 실험 데이터 선택 후 피팅 함수를 통해 그래프를 그려보세요!",
  "choose_data_title": "실험 데이터 선택",
  "select_x_axis": "x축 데이터를 선택하세요",
  "select_y_axis": "y축 데이터를 선택하세요",
  "basic_info_title": "기본 정보",
  "basic_info_subtitle": "제목",
  "basic_info_title_font_size": "제목 글자 크기",
  "basic_info_axis_font_size": "축 글자 크기",
  "basic_info_x_axis_name": "x축 이름",
  "basic_info_x_axis_min_value": "x축 최소값",
  "basic_info_x_axis_max_value": "x축 최대값",
  "basic_info_x_axis_unit": "x축 단위",
  "basic_info_x_axis_subunit": "x축 보조 단위",
  "basic_info_y_axis_name": "y축 이름",
  "basic_info_y_axis_min_value": "y축 최소값",
  "basic_info_y_axis_max_value": "y축 최대값",
  "basic_info_y_axis_unit": "y축 단위",
  "basic_info_y_axis_subunit": "y축 보조 단위",
  "basic_info_color": "데이터 색상",
  "basic_info_point_size": "포인트 크기",
  "subunit_content": "단위의 배수로 그래프 축의 숫자가 표시됩니다. 너무 촘촘할 경우 생략될 수 있습니다.",
  "add_data": "데이터 추가",
  "remove_data": "데이터 삭제",

  "fitting_function_title": "피팅 함수",
  "fitting_function_content": "함수를 선택해주세요.",

  "graph_title": "그래프",
  "graph_download_button": "그래프 이미지 다운로드",

  "polynomial_title": "다항함수",
  "polynomial_content": "조작변인과 종속변인이 다항함수 관계인 경우에 사용합니다. 예를 들어, 물체의 낙하운동에서 낙하높이와 낙하시간 사이의 상관관계는 낙하높이 = 0.5 x 9.8 x 낙하시간² 입니다. 이차 다항식의 관계에 있습니다.",

  "transcendental_title": "초월함수",
  "transcendental_content": "그런데, 다항식으로 모든 과학적 현상을 설명할 수는 없습니다. 초월함수는 사인함수와 같은 삼각함수, 지수 함수, 로그함수 등을 포함합니다. 예를 들어, 연쇄적으로 일어나는 반응에서는 지수함수를 사용합니다. 화학 반응 속도론에서 반응에 참가하는 물질의 농도에 따른 반응 속도는 로그함수로 유도됩니다.",

  "newton_title": "뉴턴의 냉각모델",
  "newton_content": "샘플이 차가워지거나 뜨거워지는 현상에 대해서 탐구하시나요? 뉴턴의 냉각모델은 이러한 현상을 분석할 때 사용됩니다. 냉각상수라고 하는 하나의 인자가 이 모델을 통해 산출됩니다. 얼마나 빨리 냉각되고 가열되는지 정량적으로 분석해보세요!",

  "logistic_title": "로지스틱 함수",
  "logistic_content": "생물 영역에서 개체군의 성장은 로지스틱 함수에 따릅니다. 효모의 개체수가 증가하는 경향, 바이러스에 전염된 사람의 수가 증가하는 경향 모두 로지스틱 함수로 분석할 수 있습니다. 로지스틱 함수는 증가율과 환경수용력 두 가지 인자로 결정됩니다.",

  // upload.js
  "upload_explanation": "실험 데이터를 업로드 할 수 있습니다! .txt, .jpg, .png 형식의 파일을 지원합니다.",
  "select_file": "파일 선택",

  // report.js
  "report_content": "모든 질문에 답하면 완성된 실험 보고서를 워드(.docx) 파일로 다운받을 수 있습니다!",
  "experiment_completion_rate": "실험 보고서 완성률",
  "select_teammate_subtitle": "원하는 팀원을 클릭하여 보고서 내용을 확인할 수 있습니다.",

  // gpt.js
  "gpt_content": "ChatGPT를 이용해서 보고서를 작성해보세요!",
  "age": "나이",
  "content": "측정 내용",
  "x_axis": "x축",
  "y_axis": "y축",
  "method": "측정 방법",
  "condition": "실험 조건",
  "result": "실험 결과",
  "difference": "달라진 점",
  "disappoint": "아쉬운 점",
  "plan": "향후 실험 계획",
  "create_report_button": "보고서 생성하기",

  // answer-card.js
  "edit_button": "수정",
  "edit_title": "보고서 수정하기",

  // lookup.js
  // "login_alert": "로그인을 해주세요!",
  "experiment_list_title": "내 실험 리스트",
  "experiment_code_title": "실험 코드",

  //profile.page.js
  "profile_pic": "프로필 사진",
  "choose_image": "이미지 선택",
  "email": "이메일",
  "change_info": "정보 수정",
  "consulting_service": "정기상담 서비스",
  "not_in_use": "미사용중",
  "use_service": "서비스 이용하기",
  "refund_service": "서비스 환불하기",
  "price": "가격 : 월 40만원",
  "duration": "서비스는 구매일로부터 30일간 제공됩니다.",
  "refund_policy": "환불 정책",
  "try_later": "나중에 하기",
  "apply_monthly_pay": "정기결제 신청하기",
  "in_use": "사용중",
  "more": "자세히",
}