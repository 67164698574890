import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'semantic-ui-react';

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const token = cookies.token;
  const user = jwt_decode(token).user;
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  const HH = String(today.getHours()).padStart(2, '0');
  const MM = String(today.getMinutes()).padStart(2, '0');
  const SS = String(today.getSeconds()).padStart(2, '0');
  const time = `${yyyy}-${mm}-${dd}_${HH}:${MM}:${SS}`;

  const query = new URLSearchParams(useLocation().search);
  const customerKey = query.get('customerKey');
  const authKey = query.get('authKey');

  useEffect(() => {
    // DB에 user의 customer_key를 업데이트.
    axios.put(`${process.env.REACT_APP_API_URL}/user/update-customer-key`, {
      email: user.email,
      customer_key: customerKey
    }).catch((e) => {
      alert(e);
    });

    // 빌링키 발급을 위한 요청 옵션
    const billingKeyIssueOptions = {
      method: 'POST',
      url: 'https://api.tosspayments.com/v1/billing/authorizations/issue',
      headers: {
        Authorization: 'Basic bGl2ZV9za19uUlFvT2FQejhMeDd4ZFlEZHhSUDh5NDdCTXc2Og==', // live secret key 입니다.
        'Content-Type': 'application/json'
      },
      data: {
        authKey: authKey,
        customerKey: customerKey
      }
    };

    // 빌링키 발급 요청
    axios.request(billingKeyIssueOptions).then(function (response) {
      const billingKey = response.data.billingKey;

      // DB에 user의 billing_key를 업데이트.
      axios.put(`${process.env.REACT_APP_API_URL}/user/update-billing-key`, {
        email: user.email,
        billing_key: billingKey
      }).catch((e) => {
        alert(e);
      });

      // 빌링키를 이용한 결제 요청 옵션
      const billingPaymentOptions = {
        method: 'POST',
        url: 'https://api.tosspayments.com/v1/billing/' + billingKey,
        headers: {
          Authorization: 'Basic bGl2ZV9za19uUlFvT2FQejhMeDd4ZFlEZHhSUDh5NDdCTXc2Og==', // live secret key 입니다.
          'Content-Type': 'application/json'
        },
        data: {
          customerKey: customerKey,
          amount: 400000,
          orderId: user.email + '_' + time,
          orderName: '그래프팅 정기 상담 서비스',
          customerEmail: user.email,
          customerName: user.name,
          taxFreeAmount: 0
        }
      };

      // 결제 요청
      axios.request(billingPaymentOptions).then(function (response) {
        const paymentKey = response.data.paymentKey; // paymentKey 추출

        // DB에 user의 subscription_until 업데이트.
        axios.put(`${process.env.REACT_APP_API_URL}/user/update-subscription-until`, {
          email: user.email
        }).then((tk) => {
          // user의 subscription_until이 바뀌었으므로 쿠키를 재설정.
          setCookie("Authentication", tk.data, {
            path: '/', httpOnly: false,
          });
          setCookie("token", tk.data, {
            path: '/', httpOnly: false,
          });

          // paymentKey를 저장
          axios.put(`${process.env.REACT_APP_API_URL}/user/update-payment-key`, {
            email: user.email,
            payment_key: paymentKey
          }).catch((e) => {
            alert(e);
          });

          setLoading(false);
        }).catch((e) => {
          setLoading(false);
          setError(e);
        });

      }).catch(function (e) {
        setLoading(false);
        setError(e);
      });

    }).catch(function (e) {
      setLoading(false);
      setError(e);
    });

  }, []);

  return (
    <div style={{ height: '60vh', display: 'flex', }}>
      {
        loading ?
          // 로딩 중
          <div style={{ margin: 'auto', textAlign: 'center' }}>결제 진행 중입니다. <br /> 잠시만 기다려 주세요.</div>
          : error ?
            // 에러 발생
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <div>에러가 발생했습니다.</div>
              <div>{error.message}</div>
              <Button style={{ marginTop: '16px' }} onClick={() => { window.location.href = '/' }}>홈으로 돌아가기</Button>
            </div>
            :
            // 결제 완료
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <div>결제가 완료되었습니다.</div>
              <Button style={{ marginTop: '16px' }} onClick={() => { window.location.href = '/' }}>홈으로 돌아가기</Button>
            </div>
      }
    </div>
  );
};

export default PaymentSuccess;